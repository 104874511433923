import React from 'react'
import {
  GridItem,
} from '@jsluna/react'

import { ITemperatureData } from '../../types/ITemperatureData'
import { getCounterTemperatureValueByPosition, updateCounterTemperatureByValue } from '../../utils/hotFoodUtils'
import { BatchTray, CabinetWindow, TurboShelf } from '../../enums/TemperatureCheckPosition'

interface IProps {
  className?: string
  counterNumber: number
  hasError: boolean
  currentTemp: ITemperatureData
  temperatures: ITemperatureData[]
  setTemperatures: (temperatures: ITemperatureData[]) => void
  isTurbo: boolean
  isModal: boolean
  isFirstCheck?: boolean
  isBluetoothReading: boolean
}

const getTurboShelfLabel = (shelf: BatchTray | CabinetWindow | TurboShelf): string => {
  switch (shelf) {
    case TurboShelf.Top:
      return 'Top'
    case TurboShelf.Second:
      return '2nd'
    case TurboShelf.Third:
      return '3rd'
    case TurboShelf.Fourth:
      return '4th'
    case TurboShelf.Fifth:
      return '5th'
    default:
      return 'Unknown'
  }
}

const CounterTempCheckInputCard = (props: IProps) => (
  <GridItem
    className='c-form-input-center'
    size={props.isModal
      ? { xs: '1/1', sm: '1/2' }
      : { xs: '1/1', sm: '1/2', md: '1/5', lg: '1/5' }}
    data-testid='countertemp-input-card'
  >
    <div className={`c-counter-input-wrapper
      ${props.isModal && !props.isTurbo ? 'c-counter-input-wrapper-modal' : ''}`}>
      {/* each input box has different label according to cabinet(1,2,3,4) or Turbo-serve(Top, Middle, Bottom) */}
      <div className={`c-counter-input-label
        ${props.isModal ? 'c-counter-input-label-modal' : ''} ln-c-label ln-u-font-weight-bold`}>
        {props.isTurbo
          ? `${getTurboShelfLabel(props.currentTemp.position)}`
          : `${props.currentTemp.position}.`}
      </div>

      <div className={`c-counter-inputbox-wrapper 
        ${props.hasError ? 'ln-c-form-group has-error ln-u-padding-top*3' : ''}`}>
        <input
          autoComplete='off'
          className={`ln-c-text-input c-form-input-content ${props.className ? `${props.className}` : ''}`}
          id={`temperature-input-${props.counterNumber}${props.currentTemp.position}`}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            props.setTemperatures(updateCounterTemperatureByValue(e.currentTarget.value,
              props.currentTemp.position,
              props.temperatures))}
          placeholder='--.- ℃'
          type='text'
          value={getCounterTemperatureValueByPosition(props.currentTemp.position, props.temperatures)}
          data-testid={`countertemp-input-${props.currentTemp.position}`}
          readOnly={ props.isBluetoothReading }
        />
      </div>

      {!props.isTurbo &&
        <a
          className='c-counter-input-button c-counter-input-button:hover'
          onClick={() => props.setTemperatures(updateCounterTemperatureByValue('n/a',
            props.currentTemp.position,
            props.temperatures))}
        >
          N/A
        </a>
      }
    </div>
  </GridItem>
)

export default CounterTempCheckInputCard

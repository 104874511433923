import React from 'react'
import { Tick } from '@jsluna/icons'
import { Card, TableBody, TableCell, TableContainer, TableHeader, TableRow } from '@jsluna/react'
import { IPicklistItem } from '../../types/IPicklistItem'

interface IGOLPlannerGroupTable {
  plannerGroupName: string
  pickList: IPicklistItem[]
  handleSkuCheckChange: (pickListItem: IPicklistItem) => void
}

const GOLPlannerGroupTable = ({ plannerGroupName, pickList, handleSkuCheckChange }: IGOLPlannerGroupTable) => {

  const renderSkuCheckbox = (pickListItem: IPicklistItem) => (
    <div className='ln-c-form-option--checkbox'>
      <input
        className='ln-c-form-option__input'
        type='checkbox'
        id={pickListItem.sainId}
        name={pickListItem.sainId}
        checked={pickListItem.completed}
        onChange={() => handleSkuCheckChange(pickListItem)}
      />
      <label
        className='ln-c-form-option__label u-no-right-padding-sm'
        htmlFor={pickListItem.sainId}
      />
    </div>)

  const allCompleted = (items: IPicklistItem[]): boolean => items.every((item) => item.completed)

  return (

    <div className='ln-u-padding-top*2 ln-u-padding-bottom*3'>
      <h4>{plannerGroupName}{allCompleted(pickList) &&
      <span className='c-gol-planner-group-complete'>{<Tick />}</span>}</h4>

      <Card>
        <TableContainer>
          <TableHeader>
            <TableRow>
              <TableCell className='c-gol-col-check'>Packed</TableCell>
              <TableCell className='c-gol-col-qty'>Qty</TableCell>
              <TableCell>Product</TableCell>
              <TableCell className='c-gol-col-skuId'>SKU Code</TableCell>
              <TableCell className='c-gol-col-sainId'>PLU Code</TableCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {pickList.map((item: IPicklistItem) =>
              <TableRow>
                <TableCell>{renderSkuCheckbox(item)}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.skuName}</TableCell>
                <TableCell>{item.skuId}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableContainer>
      </Card>
    </div>

  )
}

export default GOLPlannerGroupTable

